import React from 'react';
import Helmet from 'react-helmet';
import Page from '../components/page';
import Section from '../components/section';
import { LayoutRow, MainColumn } from '../utils/layout-row';
import StyledLink from '../components/styled-link';

const ProcessPage = ({ location }) => {
  return (
    <Page location={location}>
      <main>
        <Helmet>
          {/* Use the default description */}
          {/* <meta name="description" content=""/> */}
        </Helmet>
        <Section>
          <LayoutRow>
            <MainColumn>
              <h1>Process</h1>
              <p>Webologists are in the lab experimenting on content for this page.</p>
              <p>In the meantime feel free to check out our <StyledLink to="/">work</StyledLink>, our young <StyledLink to="/blog">blog</StyledLink>, or learn a bit more about us on our <StyledLink to="/contact">contact</StyledLink> page. Check back soon!</p>
            </MainColumn>
          </LayoutRow>
        </Section>
      </main>
    </Page>
  );
};

export default ProcessPage;
